import { useEffect, useState } from "react";
import {
    Form,
    Table,
    Button,
    Modal,
    Input,
    Avatar,
    Card,
    InputNumber,
    message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { host } from "../../config";
import { render } from "@testing-library/react";
const { Meta } = Card;
export default function App(props) {
    const token = localStorage.getItem("token");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [options, setOptions] = useState();
    const [TableData, setTableData] = useState();
    const [data, setData] = useState();
    const [selectedElement, setSelectedElement] = useState(null);
    const navigate = useNavigate();
    const screenWidth = window.innerWidth;
    const _id = window.location.pathname.split("/")[3];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchPost = (e) => {
        fetch(host + "/api/divison/id/" + _id + "/product", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchDelet = (e) => {
        fetch("host" + "/api/base", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                _id: e,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err.message));
    };

    const calculateWeightByName = (data) => {
        return Object.values(
            data.reduce((acc, item) => {
                if (!acc[item.name]) {
                    acc[item.name] = { ...item, owner: [item.owner] }; // Start with the object, converting 'owner' to an array
                } else {
                    acc[item.name].weight += item.weight; // Sum weights
                    acc[item.name].owner.push(item.owner); // Collect owners in an array
                }
                return acc;
            }, {}),
        );
    };

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: (
                        <div>{props.data.products.indexOf(index) + 1}</div>
                    ),
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Vesi",
            dataIndex: "",
            key: "weight",
            render: (e) => {
                if (e.weight) {
                    return e.weight;
                }

                if (e.usd) {
                    return (
                        (e.usd * config?.dollar_price) /
                        config?.gold_price
                    ).toFixed(2);
                }

                if (e.som) {
                    return (e.som / config?.gold_price).toFixed(2);
                }
            },
        },
        {
            title: "Pul",
            dataIndex: "som",
            key: "plus",
        },

        {
            title: "Dollar",
            dataIndex: "usd",
            key: "dollar",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Qoldi",
            key: "Qoldi",
            render: (e) => {
                var answ;
                let _data = props.data.taqsim.filter(
                    (element) => element.name == e.name,
                );

                const totalWeightByName = calculateWeightByName(_data);
                for (let u of totalWeightByName) {
                    if (e.weight) {
                        answ = e.weight - u.weight;
                    }

                    if (e.usd) {
                        answ = (
                            ((e.usd - u.usd) * config?.dollar_price) /
                            config?.gold_price
                        ).toFixed(2);
                    }

                    if (e.som) {
                        answ = ((e.som - u.som) / config?.gold_price).toFixed(
                            2,
                        );
                    }
                }
                return answ;
            },
        },
    ];

    const playSound = () => {
        // Replace 'sound.mp3' with the path to your sound file
        const audio = new Audio("/error-call-to-attention-129258.mp3");

        audio.play();
    };
    const sums = {};

    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.weight;
            const plus = item.plus;

            if (sums[owner]) {
                sums[owner].weight += weight;
                sums[owner].plus += plus;
            } else {
                sums[owner] = { weight, plus };
            }
        }
    }
    var all_plus = 0;
    data?.map((el) => (all_plus += el.plus));

    var all_weight = 0;
    data?.map((el) => (all_weight += el.weight));
    return (
        <>
            <div className="center">
                <Button
                    type="primary"
                    style={{
                        margin: "20px",
                    }}
                    onClick={showModal}
                >
                    kelibdi kelibdi
                </Button>

                <>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {record.description}
                                </p>
                            ),
                            rowExpandable: (record) => record.description,
                        }}
                        dataSource={props?.data?.products}
                        pagination={false}
                    />

                    <Modal
                        title="Yangi buyum"
                        footer={null}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form
                            onFinish={(e) => {
                                fetchPost(e);
                            }}
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                            }}
                        >
                            <Form.Item
                                name="name"
                                label="Nomi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Nomini yozing",
                                    },
                                ]}
                            >
                                <Input placeholder="" />
                            </Form.Item>
                            <Form.Item name="weight" label="GOLD">
                                <Form.Item name="weight" noStyle>
                                    <InputNumber
                                        style={{
                                            width: "auto",
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name="som" label="SOM">
                                <InputNumber
                                    style={{
                                        width: "auto",
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="usd" label="USD">
                                <Form.Item name="usd" noStyle>
                                    <InputNumber
                                        style={{
                                            width: "auto",
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name="comment" label="Comment">
                                <Input placeholder="" />
                            </Form.Item>
                            <Button
                                type="primary"
                                style={{
                                    width: "100%",
                                }}
                                htmlType="submit"
                            >
                                Qo'shish
                            </Button>
                        </Form>
                    </Modal>
                </>
            </div>
        </>
    );
}
