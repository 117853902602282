import "./Inner.css";
import { useEffect, useState } from "react";
import HeaderShop from "../../../../components/HeaderShop/Header";
import { Card, Avatar, Button, Tabs, Table } from "antd";
import Divison from "../../../../components/Divison/Divison";
import Taqsim from "../../../../components/Taqsim/Taqsim";
import { host } from "../../../../config";
import Meta from "antd/es/card/Meta";

export default function App() {
    const token = localStorage.getItem("token");
    const [config, setConfig] = useState();
    const [TableData, setTableData] = useState();
    const [selectedElement, setSelectedElement] = useState(null);
    const [data, setData] = useState();
    const _id = window.location.pathname.split("/")[3];

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));

        fetch(host + `/api/divison/id/${_id}`, {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
            })
            .catch((err) => console.log(err.message));

        // fetch(host + "/api/database/onlynames", {
        //     method: "GET",
        //     headers: {
        //         Authorization: "Bearer " + token,
        //     },
        // }).then((res) => res.json());
    }, []);

    const sums = {};
    const sums2 = {};

    const columnTrade = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data?.trade.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Model Bazasi",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Vesi",
            dataIndex: "",
            key: "weight",
            render: (e) => <p>{e.weight.toFixed(2)}</p>,
        },
        {
            title: "Ustidigi molda",
            dataIndex: "",
            key: "plus",
            render: (e) => <p>{e.plus.toFixed(2)}</p>,
        },
    ];

    const columnClient = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data?.client.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Mijoz Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Mijoz Bazasi",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Raznitsa",
            dataIndex: "",
            key: "plus",
            render: (e) => <p>{e.plus.toFixed(2)}</p>,
        },
    ];

    if (data?.trade) {
        for (const item of data?.trade) {
            const owner = item.owner.toUpperCase();
            const weight = item.weight;
            const plus = item.plus;

            if (sums[owner]) {
                sums[owner].weight += weight;
                sums2[owner].weight += weight;
                sums[owner].plus += plus;
                sums2[owner].plus += plus;
            } else {
                sums[owner] = { weight, plus };
                sums2[owner] = { weight, plus };
            }
        }

        for (const item of data?.client) {
            const owner = item.owner.toUpperCase();
            const weight = item.weight;
            const client = parseFloat(item.plus);
            if (sums[owner]) {
                sums[owner].client = item.plus;
                sums2[owner].plus += item.plus;
            } else {
                sums[owner] = { weight, client };
                sums2[owner] = { weight, client };
            }
        }

        for (const item of data?.taqsim) {
            const owner = item.owner.toUpperCase();
            var weight;

            if (item.weight) {
                weight = item.weight;
            }

            if (item.usd) {
                weight = (
                    (item.usd * config?.dollar_price) /
                    config?.gold_price
                ).toFixed(2);
            }

            if (item.som) {
                weight = (item.som / config?.gold_price).toFixed(2);
            }
            if (sums2[owner]) {
                sums2[owner].plus -= weight;
            } else {
                sums2[owner] = { weight };
            }
        }
    }

    const funGetCloud = () => {
        fetch(host + `/api/divison/id/${_id}/update/base`, {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => console.log(err));
    };

    const funGetClient = () => {
        fetch(host + `/api/divison/id/${_id}/update/client`, {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => setData(res))
            .catch((err) => console.log(err));
    };

    const onChange = (key) => {};
    const items = [
        {
            key: "1",
            label: "Keldilar",
            children: <Divison data={data} />,
        },
        {
            key: "2",
            label: "Taqsimlash",
            children: <Taqsim data={data} />,
        },
        {
            key: "3",
            label: "Trade",
            children: (
                <Table
                    columns={columnTrade}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    dataSource={data?.trade}
                    pagination={false}
                />
            ),
        },
        {
            key: "4",
            label: "Clients",
            children: (
                <Table
                    columns={columnClient}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.description,
                    }}
                    dataSource={data?.client}
                    pagination={false}
                />
            ),
        },
    ];

    var all_plus = 0;

    var all_weight = 0;
    var tradeSumm = 0;
    var clientSumm = 0;
    var productSumm = 0;

    for (let i in sums) {
        all_plus += sums[i].plus;
        all_weight += sums[i].weight;
    }

    if (data) {
        for (let x of data?.products) {
            if (x.weight) {
                all_plus -= x.weight;
                productSumm += parseFloat(x.weight);
            }

            if (x.usd) {
                all_plus -= (
                    (x.usd * config?.dollar_price) /
                    config?.gold_price
                ).toFixed(2);
                productSumm += parseFloat(
                    (
                        (x.usd * config?.dollar_price) /
                        config?.gold_price
                    ).toFixed(2),
                );
            }
            if (x.som) {
                all_plus -= (x.som / config?.gold_price).toFixed(2);
                productSumm += parseFloat(
                    (x.som / config?.gold_price).toFixed(2),
                );
            }
        }
        for (let i of data?.trade) {
            tradeSumm += i.plus;
        }
        for (let i of data?.client) {
            clientSumm += i.plus;
            all_plus += i.plus;
        }
    }

    return (
        <>
            <HeaderShop config={config} />
            <div className="center" style={{ marginBottom: "100px" }}>
                <h1 style={{ padding: "20px" }}>{data?.name}</h1>

                <div
                    className="_circle"
                    style={{
                        backgroundColor: "darkgray",
                    }}
                >
                    {tradeSumm.toFixed(2)}
                </div>
                <div
                    className="_circle"
                    style={{
                        backgroundColor:
                            all_plus.toFixed(2) < 0 ? "green" : "red",
                    }}
                >
                    {all_plus.toFixed(2)}
                </div>
                <div
                    className="_circle"
                    style={{
                        backgroundColor: "darkgray",
                    }}
                >
                    {clientSumm.toFixed(2)}
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        type="default"
                        style={{ margin: "5px" }}
                        onClick={(e) => funGetCloud()}
                    >
                        Get from Base
                    </Button>
                    <Button
                        type="default"
                        style={{ margin: "5px" }}
                        onClick={(e) => funGetClient()}
                    >
                        Get from Clients
                    </Button>
                </div>
                <div className="flex">
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                            gap: "30px",
                            margin: "30px auto",
                            alignItems: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            paddingRight: "10px",
                        }}
                    >
                        {Object.entries(sums).map(([owner, values], index) => (
                            <div key={owner} className="responsive-column">
                                <Card
                                    className="dashboard-card"
                                    style={{
                                        border:
                                            selectedElement === index
                                                ? "2px solid #1890ff"
                                                : "",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Meta
                                        avatar={
                                            <Avatar
                                                className="avatarIcon"
                                                size={"large"}
                                            >
                                                {owner}
                                            </Avatar>
                                        }
                                        title={values.weight.toFixed(2)}
                                    />
                                    <div
                                        className="center"
                                        style={{ marginTop: "5px" }}
                                    >
                                        Savdo: {values.plus.toFixed(2)} <br />
                                        Mijoz: {values.client || "0"}
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                            gap: "30px",
                            margin: "30px auto",
                            alignItems: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            borderLeft: "3px solid gray",
                            paddingLeft: "10px",
                        }}
                    >
                        {Object.entries(sums2).map(([owner, values], index) => (
                            <div key={owner} className="responsive-column">
                                <Card
                                    className="dashboard-card"
                                    style={{
                                        border:
                                            selectedElement === index
                                                ? "2px solid #1890ff"
                                                : "",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Meta
                                        avatar={
                                            <Avatar
                                                className="avatarIcon"
                                                size={"large"}
                                            >
                                                {owner}
                                            </Avatar>
                                        }
                                        title={values.weight.toFixed(2)}
                                        description={values.plus?.toFixed(2)}
                                    />
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
                <Tabs
                    style={{
                        margin: "30px",
                    }}
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                />
            </div>
            {}

            {/* <div className="footer flex">
                <div className="trade">
                    Savdo:{" "}
                    <span
                        style={{
                            color: tradeSumm < 0 ? "darkred" : "darkgreen",
                        }}
                    >
                        {tradeSumm}
                    </span>
                </div>
                <div className="Client">
                    Mijoz:{" "}
                    <span
                        style={{
                            color: clientSumm < 0 ? "darkred" : "darkgreen",
                        }}
                    >
                        {clientSumm}
                    </span>
                </div>
                <div className="trade">
                    Keldi:{" "}
                    <span
                        style={{
                            color: productSumm < 0 ? "darkred" : "darkgreen",
                        }}
                    >
                        {productSumm.toFixed(2)}
                    </span>
                </div>
            </div> */}
        </>
    );
}
